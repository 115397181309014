import React from 'react';
import ActivityIndicator from 'src/components/ActivityIndicator';

const LoadingState = ({ subtitle, ...props }) => {
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <ActivityIndicator size={64} thickness={2} {...props} />
      {subtitle && <div className="text-xl text-center pt-2">{subtitle}</div>}
    </div>
  );
};

export default LoadingState;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

import Page from 'src/components/Page';

export default function Error404() {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);

  return (
    <Page title="Not found">
      <div className="flex-grow w-full flex items-center">
        <Grid item lg={6} md={9} className="px-4 px-lg-0 mx-auto text-center">
          <h3 className="font-thin">
            The page you were looking for doesn't exist.
          </h3>
          <p className="mb-4">
            You may have mistyped the address or the page may have moved.
          </p>
          <Button
            onClick={handleBack}
            size="large"
            color="primary"
            variant="outlined"
            className="btn-go-back"
          >
            Go Back
          </Button>
        </Grid>
      </div>
    </Page>
  );
}

import React, { useEffect } from 'react';
import { Typography, Card, CardContent } from '@mui/material';

import LoginForm from 'src/components/LoginForm';
import useSnackbar from 'src/hooks/useSnackbar';

const Login = ({ location, history }) => {
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (location?.state?.message) {
      showSnackbar({ message: location.state.message, variant: 'success' });
      history.replace();
    }
  }, [location, showSnackbar, history]);

  return (
    <div className="flex flex-col items-center justify-center h-screen ">
      <Card raised={true} elevation={1} className="m-0 p-3 w-1/4">
        <CardContent className="p-3">
          <div className="text-center text-lg mb-3">
            <Typography variant="h6">Sort & Sticker</Typography>
          </div>
          <div className="mt-5">
            <LoginForm />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default Login;

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import LoadingState from 'src/components/LoadingState';

import Header from './Header';
import Sidebar from './Sidebar';

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: theme.spacing(4),
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.default,
  width: '100%',
  height: 'inherit',
  overflow: 'auto',
}));

const Section = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 64px)',
  marginTop: 64,
  display: 'flex',
  overflow: 'hidden',
}));

const DashbaordLayout = ({ actions, children, loading, title, ...props }) => {
  if (loading) {
    return <LoadingState />;
  }

  return (
    <>
      <Header />
      <Section>
        <Sidebar />
        <Main>{children}</Main>
      </Section>
    </>
  );
};

DashbaordLayout.propTypes = {
  title: PropTypes.string,
};

export default DashbaordLayout;

import { createTheme } from '@mui/material/styles';

const theme = ({ mode = 'light' }) =>
  createTheme({
    palette: {
      mode,
    },
    text: {
      error: '#e73737',
    },
  });

export default theme;

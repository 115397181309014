import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  TextField,
  InputAdornment,
  FormControl,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import PasswordField from 'src/components/PasswordField';

import useAuth from 'src/hooks/useAuth';
import useSnackbar from 'src/hooks/useSnackbar';

const LoginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });
  const { login } = useAuth();
  const { showSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    try {
      await login(data);
    } catch ({ message }) {
      showSnackbar({ message, variant: 'error' });
    }
  };

  return (
    <form className="px-md-5" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="mb-4">
        <FormControl className="w-full">
          <TextField
            fullWidth
            id="input-with-icon-adornment"
            label="Email Address"
            name="email"
            type="email"
            error={!!errors.email}
            helperText={errors?.email?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineTwoToneIcon />
                </InputAdornment>
              ),
            }}
            {...register('email')}
          />
        </FormControl>
      </div>
      <div className="mb-4">
        <FormControl className="w-full">
          <PasswordField
            id="standard-adornment-password"
            fullWidth
            label="Password"
            name="password"
            error={!!errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            {...register('password')}
          />
        </FormControl>
      </div>
      <div className="text-center">
        <LoadingButton
          color="primary"
          variant="outlined"
          size="large"
          type="submit"
          className="my-2"
          loading={isSubmitting}
        >
          Sign in
        </LoadingButton>
      </div>
      <div className="text-center mt-3">
        <Link to="/password/recovery">
          <Typography variant="body1">Forgot password?</Typography>
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;

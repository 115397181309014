import React from 'react';
import { useTheme } from '@mui/material';

const Logo = (props) => {
  const isDarkTheme = useTheme().palette.mode === 'dark';

  return (
    <svg
      id="svg474"
      version="1.1"
      viewBox="0 0 192 99"
      height="99"
      width="192"
      fill={isDarkTheme ? '#FFF' : '#00000'}
      {...props}
    >
      <g transform="translate(33.340758,-11.187406)" id="layer1">
        <path
          id="path393"
          d="m 126.49083,11.254969 c -3.04417,0.198502 -5.95628,0.803909 -8.89446,1.844397 -9.36792,3.31831 -14.43731,10.001989 -14.41816,18.983912 0.0187,8.322309 3.3303,13.933821 10.29106,17.479868 3.86269,1.962225 8.00155,3.054156 15.58008,4.143793 4.04627,0.586753 6.80102,1.146293 8.51465,1.734595 3.53274,1.232664 6.01163,3.167748 7.0675,5.524807 0.55433,1.234642 0.73945,2.160789 0.7404,3.578249 -0.009,5.627747 -4.17141,9.447833 -11.45858,10.505243 -1.83167,0.267867 -5.74575,0.298536 -7.57776,0.06113 -6.9458,-0.865507 -12.33826,-4.370496 -14.95118,-9.673711 -0.38269,-0.771636 -0.71258,-1.402957 -0.75212,-1.402931 -0.0923,6.1e-5 -10.24935,5.634549 -10.36789,5.746897 -0.13169,0.140438 1.61034,3.802222 2.48107,5.205074 2.36149,3.745582 5.44725,6.634593 9.21772,8.653019 3.32224,1.780144 6.60441,2.760364 11.16467,3.360805 2.68871,0.349075 10.10836,0.344146 12.55938,-0.0083 7.26081,-1.057382 12.95231,-3.685573 16.74509,-7.758033 2.13345,-2.274968 3.43669,-4.479214 4.39679,-7.413012 0.82859,-2.526721 0.98593,-3.733776 0.98345,-7.466885 -0.002,-3.705049 -0.1614,-4.911894 -0.99343,-7.563812 -1.99421,-6.342167 -7.05775,-10.675395 -15.26972,-13.069801 -2.67582,-0.784143 -5.1933,-1.287701 -10.0963,-2.028264 -7.14362,-1.075896 -9.14713,-1.579795 -11.73109,-2.967469 -1.04149,-0.546649 -1.51617,-0.911223 -2.28107,-1.710675 -1.121,-1.206196 -1.54331,-2.090076 -1.76841,-3.689837 -0.13241,-0.940202 -0.11947,-1.291071 0.0644,-2.287623 0.72222,-3.930081 3.58034,-6.458146 8.71924,-7.71061 1.51531,-0.379932 6.07506,-0.551371 8.03887,-0.300059 4.87655,0.600235 10.01813,3.389643 12.57707,6.798264 l 0.69911,0.939836 4.70312,-2.557364 c 2.58212,-1.405135 4.82169,-2.641643 4.97976,-2.754022 l 0.28977,-0.210746 -0.27713,-0.575221 c -0.14518,-0.322691 -0.64655,-1.192484 -1.09512,-1.921968 -3.85234,-6.228657 -10.33886,-10.069739 -18.91901,-11.21485 -2.2406,-0.307265 -6.61605,-0.430668 -8.96176,-0.274733 z"
        />
        <path
          id="path395"
          d="m -33.270114,19.585165 c -0.05052,3.312121 -0.07928,19.521726 -0.06833,36.011982 l 0.01993,30.005253 6.312638,-0.0042 6.299459,-0.0042 0.09176,-0.743871 c 0.03928,-0.392986 0.07522,-5.796201 0.0711,-11.99935 l -0.0075,-11.255477 6.180849,-0.0041 6.1808509,-0.0041 9.8129849,11.992785 9.7997992,11.978754 7.538271,0.009 c 5.904096,-0.0039 7.538238,-0.04711 7.590861,-0.187482 C 26.710386,84.902884 26.855475,85.085228 15.03786,70.904434 10.184192,65.069406 6.2009907,60.244261 6.2009537,60.188121 c -4.7e-5,-0.0702 0.632386,-0.29514 1.422974,-0.506175 4.0978453,-1.139502 7.0355573,-2.881704 9.7879753,-5.816693 2.304656,-2.457531 3.436716,-4.437113 4.46248,-7.721815 C 24.188919,38.717779 23.31361,30.424098 19.580014,24.405881 15.859622,18.415731 9.9531407,14.826882 2.2165505,13.863655 1.3862235,13.765965 -4.8342201,13.685893 -16.115297,13.637249 l -17.079761,-0.07287 z m 30.3551959,6.688218 c 5.3110891,0.05257 5.5483247,0.08051 7.3676115,1.005577 2.6893683,1.345504 4.5886053,3.589723 5.5922066,6.620457 0.396136,1.164587 0.409418,1.318948 0.42407,3.536359 0.0016,2.42793 -0.06384,3.087583 -0.405774,4.168451 -1.2230823,3.832163 -4.0547583,6.486524 -7.955064,7.415375 l -1.43625572,0.351804 -10.60892198,0.007 -10.6221003,0.0071 -0.0077,-11.620368 -0.0077,-11.620367 6.431276,0.03783 c 3.531933,0.02575 8.5794369,0.0645 11.2283889,0.09074 z"
        />
        <path
          id="path397"
          d="m 27.675437,49.70433 0.02391,35.997892 6.325816,-0.0042 6.325818,-0.0042 0.0089,-26.201992 0.02214,-26.202 10.817061,15.739269 10.81706,15.739269 0.724834,-4.82e-4 c 0.395364,-2.62e-4 0.948845,-0.04273 1.212392,-0.085 0.487561,-0.08452 0.829892,-0.561921 11.455132,-15.683892 l 10.967581,-15.585318 0.05685,26.117738 0.04376,26.131777 6.391709,-0.0042 6.391708,-0.0042 -0.0239,-35.997892 -0.0239,-35.997886 -7.287858,0.0048 -7.274688,0.0048 -7.241272,10.628752 C 67.994834,38.113335 63.952857,44.010421 63.64996,44.333412 63.412911,44.586179 63.360157,44.530084 61.605712,42.019109 60.616351,40.602306 55.854183,33.644468 51.012865,26.560382 l -8.798798,-12.863583 -7.27469,0.0048 -7.287867,0.0048 z"
        />
        <path
          id="path399"
          d="m -2.0824671,89.090224 c -3.0045023,0.394956 -5.2966694,1.813939 -6.7579388,4.1867 -1.1979841,1.937526 -1.5397831,3.214873 -1.5380311,5.853309 0.0015,2.231447 0.226286,3.382107 0.9257871,4.925417 0.989863,2.18869 2.7700706,3.80145 5.0769082,4.62793 1.3840999,0.49029 4.29670438,0.62869 5.864746,0.27679 1.1727337,-0.26743 2.8063627,-1.0825 3.8601071,-1.92525 l 0.7639673,-0.60398 -1.1605523,-1.23425 c -1.0814354,-1.16412 -1.1605437,-1.22021 -1.4239901,-1.02356 -1.2908506,1.01132 -2.1208608,1.3908 -3.50449462,1.60224 -2.24017808,0.32427 -4.40198328,-0.40407 -5.64177618,-1.89088 -2.176253,-2.637 -2.100107,-7.043801 0.165003,-9.515336 1.2774189,-1.390249 2.8452927,-1.99476 4.94079578,-1.883882 1.4233656,0.08325 2.28018122,0.377415 3.38771062,1.14856 L 3.561398,94.124777 4.6940664,93.057424 C 5.7740447,92.032207 5.8135447,91.97604 5.6025166,91.72356 5.3255389,91.386924 3.8488235,90.349375 3.0841853,89.942883 1.8053869,89.256052 -0.46161632,88.878638 -2.0824671,89.090224 Z"
        />
        <path
          id="path401"
          d="m 12.836101,89.361005 c -3.0173693,0.858095 -5.2166423,3.245376 -6.2422663,6.740598 -0.262979,0.898365 -0.302226,1.33345 -0.301061,3.08773 0.01475,2.357747 0.186636,3.213717 1.018095,5.009557 0.989853,2.17465 2.677752,3.70326 4.9186663,4.4877 1.436814,0.49024 4.138547,0.61476 5.680225,0.24884 1.673451,-0.38004 3.096231,-1.18093 4.189278,-2.37457 1.119403,-1.19366 1.685438,-2.17643 2.22467,-3.83284 0.341931,-1.08086 0.407561,-1.47386 0.459177,-3.129937 0.06431,-2.385864 -0.147305,-3.522503 -0.978772,-5.332372 -0.514699,-1.094328 -0.8181,-1.529196 -1.688514,-2.454879 -1.12099,-1.192165 -2.017538,-1.78101 -3.507097,-2.313321 -1.265471,-0.462289 -4.402077,-0.544416 -5.772401,-0.136506 z m 4.285101,3.000485 c 1.107223,0.308014 1.898266,0.784659 2.636792,1.556049 3.006888,3.197823 2.273099,9.555821 -1.310282,11.438791 -1.580904,0.82907 -3.966218,0.90084 -5.403198,0.15797 -0.316403,-0.1682 -0.619598,-0.29431 -0.685493,-0.29426 -0.263575,1.7e-4 -1.279041,-1.05172 -1.767179,-1.83732 -1.5171713,-2.42692 -1.5196133,-6.103893 -0.01884,-8.51878 0.7504,-1.193409 2.133492,-2.218838 3.490674,-2.570589 0.672007,-0.168857 2.332559,-0.12786 3.057524,0.06817 z"
        />
        <path
          id="path403"
          d="m 65.419452,89.32608 c -2.93831,0.844001 -5.163986,3.16114 -6.216099,6.45989 -0.447144,1.403727 -0.524112,4.57551 -0.140863,6.17517 0.436138,1.86626 1.095906,3.11487 2.322409,4.43329 0.857238,0.92569 1.279171,1.2482 2.188827,1.72475 1.634729,0.84098 2.675981,1.03677 4.995396,0.95101 1.700022,-0.0572 2.029455,-0.11361 2.964912,-0.46509 2.648249,-1.04029 4.663051,-3.37133 5.464966,-6.3752 0.394391,-1.45982 0.49818,-3.929917 0.22048,-5.361226 -0.714084,-3.648437 -2.837597,-6.257397 -6.027631,-7.406088 -1.265467,-0.46229 -4.402072,-0.544417 -5.772397,-0.136506 z m 3.916062,2.944589 c 2.042952,0.363533 3.744102,2.004414 4.510029,4.347626 0.409388,1.276848 0.424013,3.452155 0.01641,4.841815 -0.315534,1.13698 -1.092189,2.48478 -1.750738,3.07466 -1.962465,1.76963 -5.415202,1.9263 -7.590748,0.3559 -3.032585,-2.20136 -3.418667,-8.067431 -0.732086,-10.918166 0.671652,-0.702156 1.936303,-1.474886 2.740067,-1.68593 0.711529,-0.182923 1.805369,-0.18365 2.807071,-0.01587 z"
        />
        <path
          id="path405"
          d="m -33.045947,99.145293 0.0065,9.753807 1.647347,-0.001 1.647349,-0.001 -0.0065,-9.753807 -0.0065,-9.753807 -1.647348,0.0011 -1.647348,0.0011 z"
        />
        <path
          id="path407"
          d="m -27.247282,99.141433 0.0065,9.753817 1.647348,-0.001 1.647348,-0.001 -0.0043,-6.52594 c -0.0024,-3.592757 0.04838,-6.525952 0.101096,-6.525987 0.131788,-8.7e-5 0.276885,0.196296 5.671911,7.574737 2.070943,2.81951 3.785742,5.16209 3.838485,5.20416 0.03958,0.0561 0.566782,0.13997 1.173043,0.1957 l 1.080726,0.0975 -0.0065,-9.767847 -0.0065,-9.767846 -1.713243,0.0011 -1.713242,0.0011 0.004,5.964559 c 0.0022,3.284012 -0.03558,5.964584 -0.08829,5.964614 -0.10543,7e-5 -2.281849,-2.889542 -6.647951,-8.79506 l -2.295189,-3.128117 -1.344237,8.93e-4 -1.357414,9.02e-4 z"
        />
        <path
          id="path409"
          d="m 26.390361,99.091783 0.0065,9.767847 1.713242,-10e-4 1.713243,-0.001 -0.0022,-3.29805 -0.0022,-3.29806 1.700061,-0.001 1.700064,-0.001 2.677494,3.31032 2.690661,3.29626 1.871362,-0.0433 c 1.027924,-0.0288 1.910868,-0.0855 1.950364,-0.14164 0.09219,-0.0983 -0.395845,-0.72952 -3.521718,-4.48863 -1.002386,-1.20628 -1.820144,-2.2162 -1.820172,-2.2583 -2.8e-5,-0.0421 0.31617,-0.18266 0.711459,-0.2952 0.856444,-0.26721 2.160537,-1.18031 2.634536,-1.840237 2.225155,-3.103041 1.246774,-7.845982 -1.969996,-9.570056 -1.344714,-0.729467 -1.634675,-0.771384 -7.011665,-0.837983 l -5.047523,-0.06685 z m 10.222681,-6.111699 c 1.766527,0.854916 2.282214,3.436884 1.004888,4.967459 -0.869119,1.02509 -1.106299,1.08138 -4.730465,1.08378 l -3.202445,0.002 -0.0021,-3.157706 -0.0021,-3.157709 3.202446,-0.0021 c 2.80708,-0.0019 3.255188,0.03994 3.729771,0.264173 z"
        />
        <path
          id="path411"
          d="m 42.956029,98.982533 c 0.04307,5.318967 0.08549,9.669557 0.09867,9.669547 9e-6,0.014 0.738059,0.0697 1.634253,0.12523 l 1.607877,0.0971 -0.0019,-2.87703 -0.0019,-2.87702 3.004708,-0.0862 c 3.545018,-0.11463 4.414733,-0.24152 5.468655,-0.81762 2.160555,-1.15224 3.384699,-3.384507 3.382882,-6.12118 -0.0017,-2.624409 -1.294737,-4.840971 -3.483188,-6.004358 -1.252414,-0.644738 -2.227709,-0.74233 -7.617841,-0.75279 l -4.177674,0.0028 z m 9.800988,-6.069302 c 1.370939,0.518349 2.096546,1.682718 2.097655,3.352797 8.21e-4,1.235005 -0.288637,1.950945 -1.039372,2.639125 -0.302925,0.28088 -0.816704,0.57594 -1.132939,0.66036 -0.329414,0.0844 -1.80539,0.15557 -3.479095,0.15669 l -2.912512,0.002 -0.0023,-3.508555 -0.0023,-3.508569 2.952048,-0.002 c 2.293108,-0.0015 3.070685,0.04006 3.518875,0.208183 z"
        />
        <path
          id="path413"
          d="m 78.973723,99.070883 0.0065,9.795917 1.093784,-0.0849 c 0.593003,-0.0565 1.330984,-0.0991 1.647284,-0.0993 l 0.5535,-3.7e-4 -8.2e-4,-1.23501 c -4.48e-4,-0.67365 0.03809,-2.1192 0.09016,-3.22794 l 0.07778,-1.99292 1.66053,-0.001 1.660529,0.0129 2.69066,3.28224 2.69066,3.29626 1.331051,-0.0149 c 0.738,-0.0145 2.121742,-0.0575 3.096935,-0.11433 l 1.765902,-0.0854 0.75002,-1.75479 0.73685,-1.75477 4.770692,-0.0453 4.7707,-0.0313 0.26388,0.44893 c 0.13195,0.25252 0.50149,1.06626 0.80511,1.80988 l 0.55439,1.34692 1.33111,0.0693 c 0.73805,0.0416 1.55513,0.0411 1.81866,-0.0152 l 0.47438,-0.0845 -1.94035,-4.63003 c -1.06918,-2.55353 -2.89073,-6.888901 -4.0523,-9.65288 l -2.09878,-5.036907 -1.84495,0.09943 c -1.01475,0.04277 -1.88449,0.127561 -1.92399,0.183728 -0.0527,0.05613 -1.34217,3.102462 -2.881662,6.766435 -3.736855,8.914234 -4.618484,10.949794 -4.737094,10.949864 -0.11861,8e-5 -4.391922,-5.10555 -4.457961,-5.31602 -0.02645,-0.0702 0.21072,-0.21066 0.526964,-0.29507 1.238557,-0.36572 2.568908,-1.4332 3.200715,-2.598463 0.737124,-1.33375 0.933256,-3.66357 0.457755,-5.277194 -0.594402,-2.034576 -1.952734,-3.423064 -4.08817,-4.123356 -0.60635,-0.196077 -1.502548,-0.251622 -5.759334,-0.304934 l -5.047527,-0.08087 z m 10.486383,-5.91538 c 1.911843,1.37408 1.768915,4.44768 -0.24668,5.58579 -0.526964,0.29508 -0.711455,0.30923 -3.716205,0.32526 l -3.162909,0.002 -0.04162,-3.199779 -0.02852,-3.185762 3.255159,-0.0022 3.268339,-0.0022 z m 14.642304,1.014775 c 1.49158,3.563705 2.94371,7.281822 2.8911,7.436232 -0.0526,0.14038 -0.77743,0.18297 -3.37365,0.18469 -2.95204,0.002 -3.29471,-0.0259 -3.29484,-0.22236 -9e-5,-0.12631 0.63139,-1.782777 1.3945,-3.691947 1.47361,-3.635841 1.85521,-4.506215 1.97382,-4.506294 0.0395,-2.6e-5 0.22428,0.364731 0.40907,0.799679 z"
        />
        <path
          id="path415"
          d="m 110.20204,90.910275 0.001,1.61394 2.89933,-0.0019 2.89933,-0.0019 0.005,8.153895 0.005,8.16794 1.09378,-0.0849 c 0.59301,-0.0565 1.331,-0.0991 1.64729,-0.0993 l 0.55351,-3.7e-4 -0.003,-3.97169 c -0.001,-2.17531 0.0356,-5.810206 0.0869,-8.069756 l 0.0764,-4.098062 2.88616,-0.0019 2.87297,-0.0019 -0.001,-1.613939 -0.001,-1.61394 -7.5119,0.005 -7.5119,0.005 z"
        />
        <path
          id="path417"
          d="m 126.15377,99.039553 0.006,9.753807 6.85297,-0.005 6.85297,-0.005 -0.001,-1.82446 -0.001,-1.82445 -5.13972,0.003 -5.13973,0.003 -0.001,-2.24548 -0.001,-2.24549 4.94205,-0.003 4.94204,-0.003 -0.001,-1.684107 -0.001,-1.684101 -4.94203,0.0033 -4.94205,0.0033 -0.001,-2.24548 -0.001,-2.245489 5.13973,-0.0034 5.13973,-0.0034 -0.001,-1.75428 -0.001,-1.754279 -6.85297,0.0046 -6.85297,0.0046 z"
        />
        <path
          id="path419"
          d="m 141.17759,99.043613 0.006,9.767837 4.32258,-0.10107 c 4.92878,-0.11555 5.64038,-0.21426 7.27398,-1.07144 2.17373,-1.15225 3.7011,-3.23034 4.34506,-5.93938 0.35481,-1.51593 0.36636,-3.971937 -0.004,-5.473357 -0.5286,-2.18899 -2.05884,-4.447494 -3.74646,-5.541043 -1.58212,-1.00942 -2.2148,-1.16338 -5.41734,-1.287562 -1.59466,-0.05504 -3.7692,-0.109767 -4.83669,-0.109058 l -1.95047,-0.0127 z m 9.90659,-5.816771 c 2.1489,1.135353 3.27062,3.436219 3.10124,6.355461 -0.11726,2.035047 -0.9201,3.649517 -2.31638,4.660917 -1.29088,0.95518 -1.91019,1.09594 -4.8227,1.09787 l -2.50397,0.002 -0.0437,-6.371517 -0.0306,-6.357507 2.80711,0.04024 2.80711,0.05424 z"
        />
      </g>
    </svg>
  );
};

export default Logo;

import React, { useState, useEffect, useCallback } from 'react';
import AuthContext from './context';
import { useNavigate } from 'react-router-dom';
import api from 'src/services/api';
import SuspenseLoading from '../SuspenseLoading';

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);

  const navigate = useNavigate();

  const [hasInitialized, setInitialized] = useState(false);

  const onAuthenticationSuccess = useCallback(async () => {
    const { role, ...currentUser } = await api.user.me();
    setUser(currentUser);
    setRole(role);
  }, [setUser]);

  const onTokenChange = useCallback(
    async (token) => {
      api.setToken(token);
      return onAuthenticationSuccess();
    },
    [onAuthenticationSuccess],
  );

  const handleToken = useCallback(
    async (token) => {
      await onTokenChange(token);
      const newState = {
        token,
        isAuthenticated: true,
      };
      window.localStorage.setItem('token', token);
      setAuth(newState);
    },
    [setAuth, onTokenChange],
  );

  const login = async (payload) => {
    const { token } = await api.token.create(payload);
    if (token) {
      return handleToken(token);
    }
  };

  const logout = useCallback(() => {
    window.localStorage.removeItem('token');
    api.setToken(null);
    setAuth(null);
    setRole(null);
    setUser(null);
    navigate('/login');
  }, [setAuth, setRole, setUser, navigate]);

  const handleNewRole = async () => {
    const { token } = await api.token.switch();
    if (token) {
      return handleToken(token);
    }
  };

  useEffect(() => {
    const init = async () => {
      const token = window.localStorage.getItem('token');
      if (token) {
        try {
          await handleToken(token);
        } catch ({ message }) {
          // TODO: Show error
          logout();
        }
      }
      return setInitialized(true);
    };
    init();
  }, [handleToken, logout, setInitialized]);

  useEffect(() => {
    const handleUnauthorized = () => {
      if (auth && auth.isAuthenticated) logout();
    };

    api.on('unauthorized', handleUnauthorized);
    return () => {
      api.off('unauthorized', handleUnauthorized);
    };
  }, [auth, logout]);

  // FIXME: The sync functions below are temp for updates, they need to be dry
  // probably use useReducer
  const syncUser = (payload) => {
    setUser({ ...user, ...payload });
  };

  if (!hasInitialized) return <SuspenseLoading noSubtitle />;

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        user,
        role,
        logout,
        login,
        syncUser,
        handleNewRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { PrinterSetupButton } from 'src/components/PrinterSetup';

import { primary } from './items';

const Root = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  top: '0',
  left: '0px',
  overflowY: 'auto',
  overflowX: 'hidden',
  backgroundColor: 'inherit',
  borderRight: `1px solid ${theme.palette.divider}`,
  WebkitUserSelect: 'none',
  userSelect: 'none',
  zIndex: 1099,
  width: 240,
  height: 'inherit',
}));

const Content = styled('section')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingY: theme.spacing(2),
}));

const Sidebar = () => {
  const location = useLocation();
  const renderItem = (item, index) => {
    const { Icon, label, url } = item;
    const props = url
      ? {
          component: Link,
          to: url,
          style: { color: 'inherit' },
          underline: 'none',
        }
      : null;

    return (
      <ListItem selected={location.pathname === url} disablePadding key={index}>
        <ListItemButton className="px-10" {...props}>
          {Icon && (
            <ListItemIcon className="min-w-0 mr-2">
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText color="secondary" primary={label} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Root>
      <Content>
        <List className="mt-4">{primary.map(renderItem)}</List>
        <PrinterSetupButton className="fixed bottom-[10px] left-[10px] w-16 h-16" />
      </Content>
    </Root>
  );
};

export default Sidebar;

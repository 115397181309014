import { useSnackbar as useNotiSnackbar } from 'notistack';

const useSnackbar = () => {
  const { enqueueSnackbar } = useNotiSnackbar();

  const showSnackbar = ({ variant, message, ...props }) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 4000,
      ...props,
    });
  };

  return { showSnackbar };
};

export default useSnackbar;

import React, { forwardRef, useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordField = forwardRef(({ InputProps, ...rest }, ref) => {
  const [isVisible, setVisibility] = useState();
  const toggleVisibility = () => setVisibility(!isVisible);

  return (
    <TextField
      ref={ref}
      type={isVisible ? 'text' : 'password'}
      autoComplete="off"
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleVisibility}
            >
              {isVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
});

export default PasswordField;

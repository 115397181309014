import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import Drawer from 'src/components/Drawer';

import useAuth from 'src/hooks/useAuth';

import User from './User';

const SettingsDrawer = styled(Drawer)`
  color: ${({ theme }) => theme};
`;

const onClick = (activator, callback) => (event) => {
  if (activator.props.onClick) {
    activator.props.onClick(event);
  }

  if (event.defaultPrevented) {
    return;
  }

  callback();
};

const Settings = ({ activator, ...props }) => {
  const [isOpen, setOpen] = useState(false);
  const { user, logout } = useAuth();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const anchor = React.cloneElement(activator, {
    onClick: onClick(activator, openDrawer),
  });

  return (
    <Fragment>
      {anchor}
      <SettingsDrawer {...props} open={isOpen} onRequestClose={closeDrawer}>
        <User user={user} />
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/account">
              <ListItemText>Profile & Password</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={logout}>
              <ListItemText>Logout</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </SettingsDrawer>
    </Fragment>
  );
};

export default Settings;

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ScanIcon from '@mui/icons-material/CropFree';
import UploadIcon from '@mui/icons-material/Upload';
import SettingsIcon from '@mui/icons-material/Settings';
import PalletIcon from '@mui/icons-material/GridOn';
import BoxIcon from '@mui/icons-material/Rectangle';

export const primary = [
  {
    Icon: DashboardIcon,
    label: 'Dashboard',
    url: '/',
  },
  {
    Icon: ScanIcon,
    label: 'Scan',
    url: '/scan',
  },
  {
    Icon: AccountCircleIcon,
    label: 'Users',
    url: '/user',
  },
  {
    Icon: UploadIcon,
    label: 'Imports',
    url: '/import',
  },
  {
    Icon: BoxIcon,
    label: 'Boxes',
    url: '/box',
  },
  {
    Icon: PalletIcon,
    label: 'Pallets',
    url: '/pallet',
  },
];

export const bookmarks = [];

export const secondary = [
  {
    Icon: SettingsIcon,
    label: 'Settings',
    url: '/settings',
  },
];

import React from 'react';

import ErrorState from 'src/components/ErrorState';

const action = {
  content: 'Refresh',
  onAction: () => window.location.reload(),
};

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    // TODO: log error to some service
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    const { error, hasError } = this.state;

    if (hasError) {
      return (
        <>
          {error.name === 'ChunkLoadError' ? (
            <ErrorState
              title="Update!"
              subtitle="This application has been updated, please refresh the page."
              action={action}
            ></ErrorState>
          ) : (
            <ErrorState subtitle="Something went wrong."></ErrorState>
          )}
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import Resource from '../Resource';

class Item extends Resource {
  static path = '/item';

  import = Resource.method({
    method: 'POST',
    path: '/import',
  });

  importList = Resource.method({
    method: 'GET',
    path: '/import',
  });
}

export default Item;

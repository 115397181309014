// FIXME: WTF...Why doesn't the local take priority over the global!
import EventEmitter from 'events';
import env from 'src/env';

import Token from './resources/Token';
import User from './resources/User';
import Password from './resources/Password';
import Item from './resources/Item';
import Configuration from './resources/Configuration';
import Box from './resources/Box';
import Pallet from './resources/Pallet';

const resources = {
  Token,
  User,
  Password,
  Item,
  Configuration,
  Box,
  Pallet,
};

const self = Symbol('self');

export class API {
  static DEFAULT_HOST = env('API_BASE_URL');
  static DEFAULT_PROTOCOL = 'http';
  static DEFAULT_BASE_PATH = env('API_VERSION');
  static DEFAULT_VERSION = env('API_VERSION');
  static DEFAULT_TIMEOUT = 30000;

  constructor() {
    this[self] = {
      host: API.DEFAULT_HOST,
      protocol: API.DEFAULT_PROTOCOL,
      basePath: API.DEFAULT_BASE_PATH,
      version: API.DEFAULT_VERSION,
      timeout: API.DEFAULT_TIMEOUT,
      token: null,
    };

    Object.defineProperty(this, 'emitter', {
      value: new EventEmitter(),
      enumerable: false,
      configurable: false,
      writeable: false,
    });

    this.on = this.emitter.on.bind(this.emitter);
    this.off = this.emitter.off.bind(this.emitter);

    Object.entries(resources).forEach(([name, Resource]) => {
      this[name[0].toLowerCase() + name.substring(1)] = new Resource(this);
    });
  }

  const(name) {
    return this.constructor[name];
  }

  set(key, value) {
    this[self][key] = value;
  }

  get(key) {
    return this[self][key];
  }

  setToken(value) {
    this[self]['token'] = value;
  }
}

const api = new API();

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  window.api = api;
}

export default api;

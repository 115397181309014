import Resource from '../Resource';

class Password extends Resource {
  static path = '/password';

  recovery = Resource.method({
    method: 'POST',
    path: '/request',
  });

  getRecoveryToken = Resource.method({
    method: 'GET',
    path: '/request/:secret',
  });

  update = Resource.method({
    method: 'POST',
    path: '/',
  });
}

export default Password;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const info = {
  date: process.env.REACT_APP_DATE,
  version: process.env.REACT_APP_VERSION,
  commit: process.env.REACT_APP_COMMIT,
  build: process.env.REACT_APP_BUILD_NUM,
};

const app = (window.app = {});
Object.entries(info).forEach(([key, value]) => {
  if (value) app[key] = value;
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

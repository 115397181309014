import Resource from '../Resource';

class Configuration extends Resource {
  static path = '/configuration';

  roles = Resource.method({
    method: 'GET',
    path: '/role',
  });

  qzCertificate = Resource.method({
    method: 'GET',
    path: '/qz/certificate',
  });

  qzSign = Resource.method({
    method: 'POST',
    path: '/qz/sign',
  });
}

export default Configuration;

import { Outlet, Navigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

const GuestOnlyOutlet = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    const path = localStorage.getItem('redirect') || '/';
    localStorage.removeItem('redirect');
    return <Navigate to={path} />;
  }

  return <Outlet />;
};

export default GuestOnlyOutlet;

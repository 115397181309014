import React, { Fragment, useEffect } from 'react';

function useDocumentTitle(title) {
  useEffect(() => {
    if (title) document.title = title;
  });
}

const Page = ({ title, children }) => {
  useDocumentTitle(title);

  return <Fragment>{children}</Fragment>;
};

export default Page;

import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';

import PrinterSetup from './PrinterSetup';
import { PrinterSetupIcon, PrinterErrorIcon } from './Icons';

const PrinterSetupButton = ({ ...props }) => {
  const [showPrinterSetup, setShowPrinterSetup] = useState(false);
  const [hasConfigured, setHasConfigured] = useState(false);

  useEffect(() => {
    const preferences = JSON.parse(
      window.localStorage.getItem('printing-preferences'),
    );
    setHasConfigured(preferences?.status === 'ok');
  }, [setHasConfigured, showPrinterSetup]);

  useEffect(() => {
    const hanldePreferenceChange = ({ detail }) => {
      setHasConfigured(detail?.status === 'ok');
    };
    window.addEventListener(
      'recalls-printer-configuration-closed',
      hanldePreferenceChange,
      false,
    );

    return () =>
      window.removeEventListener(
        'recalls-printer-configuration-closed',
        hanldePreferenceChange,
      );
  }, [setHasConfigured]);

  const handleClick = () => setShowPrinterSetup(true);

  const handleClose = () => setShowPrinterSetup(false);

  return (
    <>
      <IconButton
        title={
          hasConfigured ? 'Printer Setttings.' : 'Printer not configured yet.'
        }
        onClick={handleClick}
        {...props}
      >
        {hasConfigured ? <PrinterSetupIcon /> : <PrinterErrorIcon />}
      </IconButton>
      {showPrinterSetup && <PrinterSetup onClose={handleClose} />}
    </>
  );
};

export default PrinterSetupButton;

import React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Settings from 'src/components/Settings';

import Logo from './Logo';

import useAuth from 'src/hooks/useAuth';

const Root = styled('div')(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
}));

const LogoWrap = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 64,
  width: 240,
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  boxShadow: 'none',
  backdropFilter: 'blur(20px)',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledAvatar = styled(Avatar)({
  cursor: 'pointer',
  width: 32,
  height: 32,
  margin: -4,
});

function Header(props) {
  const { user } = useAuth();

  return (
    <Root>
      <StyledAppBar position="static" color="transparent">
        <div className="flex align-items-center">
          <LogoWrap>
            <Logo
              style={{ filter: 'saturate(0)', padding: '0 5px' }}
              width="64"
            />
            <p className="mb-1 font-thin">Sort & Sticker</p>
          </LogoWrap>
        </div>
        <div className="flex align-items-center">
          <Toolbar>
            {user && (
              <Settings
                activator={
                  <Chip
                    avatar={
                      <StyledAvatar alt={user.fullName} src={user.avatarUrl} />
                    }
                    label={user.fullName}
                    variant="outlined"
                  />
                }
              />
            )}
          </Toolbar>
        </div>
      </StyledAppBar>
    </Root>
  );
}

export default Header;

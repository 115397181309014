import React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '64px',
  padding: '16px 24px',
  borderBottom: `1px solid ${({ theme }) => theme.palette.text.secondary}`,
  '& > div:first-of-type': {
    marginRight: '24px',
  },
});

const Info = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
  flexGrow: '1',
  alignItems: 'flex-start',
  color: 'inherit',
  textDecoration: 'none',
});

const Name = styled('div')({
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '16px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Handle = styled('div')({
  display: 'block',
  maxWidth: '100%',
  fontSize: '12px',
  lineHeight: '16px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const User = ({ user }) => (
  <Root>
    <Avatar src={user.avatarUrl} style={{ width: 32, height: 32 }} />
    <Info>
      <Name>{user.fullName}</Name>
      <Handle>{user.email}</Handle>
    </Info>
  </Root>
);

export default User;

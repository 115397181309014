import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import AuthProvider from 'src/components/AuthProvider';
import ErrorBoundary from 'src/components/ErrorBoundary';

import Router from './Router';
import theme from './theme';

function App() {
  const mode = useMediaQuery('(prefers-color-scheme: dark)')
    ? 'light'
    : 'light';
  return (
    <BrowserRouter basename="/">
      <AuthProvider>
        <ThemeProvider theme={theme({ mode })}>
          <CssBaseline enableColorScheme />
          <SnackbarProvider maxSnack={1}>
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
          </SnackbarProvider>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;

import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import SuspenseLoading from 'src/components/SuspenseLoading';
import ProtectedOutlet from 'src/components/ProtectedOutlet';
import GuestOnlyOutlet from 'src/components/GuestOnlyOutlet';

import Login from 'src/pages/Login';
import Error404 from 'src/pages/Error404';

const Dashboard = lazy(() => import('src/pages/Dashboard'));
const Users = lazy(() => import('src/pages/Users'));
const EditUser = lazy(() => import('src/pages/EditUser'));
const NewUser = lazy(() => import('src/pages/NewUser'));
const Account = lazy(() => import('src/pages/Account'));
const PasswordRecovery = lazy(() => import('src/pages/PasswordRecovery'));
const PasswordReset = lazy(() => import('src/pages/PasswordResetPage'));
const NewImport = lazy(() => import('src/pages/NewImport'));
const Imports = lazy(() => import('src/pages/Imports'));
const Boxes = lazy(() => import('src/pages/Boxes'));
const Pallets = lazy(() => import('src/pages/Pallets'));
const Settings = lazy(() => import('src/pages/Settings'));

const Router = () => {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Routes>
        <Route path="/" element={<ProtectedOutlet />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/account" element={<Account />} />
          <Route path="/user/:id/update" element={<EditUser />} />
          <Route path="/user/new" element={<NewUser />} />
          <Route path="/user" element={<Users />} />
          <Route path="/import" element={<Imports />} />
          <Route path="/import/new" element={<NewImport />} />
          <Route path="/box" element={<Boxes />} />
          <Route path="/pallet" element={<Pallets />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<Error404 />} status={404} />
        </Route>
        <Route path="/" element={<GuestOnlyOutlet />}>
          <Route path="/login" element={<Login />} />
          <Route path="/password/recovery" element={<PasswordRecovery />} />
          <Route path="/password/reset/:secret" element={<PasswordReset />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;

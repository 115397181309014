import React from 'react';
import LoadingState from 'src/components/LoadingState';

const SuspenseLoading = ({ noSubtitle, subtitle }) => {
  return (
    <>
      <div className="flex items-center flex-col h-screen justify-center text-center p-3">
        <div className="flex items-center flex-col px-4">
          <LoadingState />
        </div>
        {!noSubtitle && (
          <div className="text-center pt-2">
            {subtitle || 'Please wait while we load the page..'}
          </div>
        )}
      </div>
    </>
  );
};

export default SuspenseLoading;

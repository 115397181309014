import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';

const getButtonProps = (action) => {
  const buttonProps = {
    color: 'primary',
    variant: 'contained',
    onClick: action.onAction,
  };

  if (action.url) {
    buttonProps.component = Link;
    buttonProps.to = action.url;
  }

  return buttonProps;
};

const ErrorState = ({ title, subtitle, action, className }) => {
  return (
    <div className="flex-grow w-full flex items-center">
      <Grid item lg={6} md={9} className="px-4 px-lg-0 mx-auto text-center">
        <Typography className="font-thin" color="error" variant="h4">
          {title || 'Oops!'}
        </Typography>
        {subtitle && (
          <Typography className="font-thin" variant="subtitle1">
            {subtitle}
          </Typography>
        )}
        {action && (
          <div className="flex mt-5 justify-center">
            <Button {...getButtonProps(action)}>{action.content}</Button>
          </div>
        )}
      </Grid>
    </div>
  );
};

const ActionType = PropTypes.shape({
  content: PropTypes.string,
  url: PropTypes.string,
  onAction: PropTypes.func,
});

ErrorState.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: ActionType,
};

export default ErrorState;

import React, { Suspense } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';

import SuspenseLoading from 'src/components/SuspenseLoading';
import ErrorBoundary from 'src/components/ErrorBoundary';
import DashboardLayout from 'src/components/DashboardLayout';

import useAuth from 'src/hooks/useAuth';

const ProtectedOutlet = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <DashboardLayout>
      <Suspense fallback={<SuspenseLoading />}>
        <ErrorBoundary key={location?.pathname}>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </DashboardLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedOutlet;

import Resource from '../Resource';

class User extends Resource {
  static path = '/user';
  static include = [
    Resource.CREATE,
    Resource.LIST,
    Resource.UPDATE,
    Resource.GET,
  ];

  me = Resource.method({
    method: 'GET',
    path: '/me',
  });

  updateMe = Resource.method({
    method: 'PATCH',
    path: '/me',
  });
}

export default User;
